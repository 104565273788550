/**
 *
 * Provides the browser specific functions for the showcase viewer
 *
 *
 */


/* public */
/* public */
/* public */
/* public */

import $ from "jquery";
import _ from 'underscore'; // findWhere
import ScNotification from '../../shared/common/ScNotification.vue';
import BrowserViewerAnalyticsLogger from './BrowserViewerAnalyticsLogger';
import ScCommonUtil from '../../shared/common/ScCommonUtil';
import BrowserViewerPreload from './BrowserViewerPreload';
import ScVueTrackEvents from '../global/ScVueTrackEvents';
import store from '../../store'

let _contactSuggestionListWorkspaceId = null;
let _lastShowcaseShownInFullscreen = false;

let BrowserViewerEmbedHandler = {

    handlers: {
        HtmlZipHandler: function (resource, scData, zIndex, workspaceId, userEmail, sharedKey) {
            //console.log('HtmlZipHandler');
            let _showcaseId = scData.presentation.id;
            let $_htmlEmbedIframe;
            let indexRelPath = null;
            let rmd = _.findWhere(scData.resourcemetadata, {resource_id: resource.id, name: 'html_zip_browser_url'});
            if (rmd && rmd.value) {
                indexRelPath = rmd.value;
            }

            //let indexRelPath = 'https://45s1k5wd6ilsxp111vu3lm.html-zip-dev1.dev.showcaseworkshop.com/_showcase/auth?hzwaj=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzZG0iOiI0NXMxazV3ZDZpbHN4cDExMXZ1M2xtLnVzZXItY29udGVudC1kZXYxLmRldi5zaG93Y2FzZXdvcmtzaG9wLmNvbSIsInNicCI6IjJYMkhMRjg2QzlXMURaOFdOSE00UUtSSk9MR0lMRzVWM0xEMTY5UzAvci8yVVNJSFlXTlBKU1FGRllFRVE5MEZELzUwOTExIiwic2loIjoiaW5kZXguaHRtbCIsImV4cCI6MTU3NjE5ODA5OX0.iyhw7EWTEbiKGCu-kGzxsH48kH-Ph2yvflncOpOgVYM';
            //indexRelPath = 'http://127.0.0.1:8000/';

            let iframeOrigin = null;
            if (indexRelPath) {
                let relPathAEle = document.createElement("a");
                relPathAEle.href = indexRelPath;
                iframeOrigin = relPathAEle.origin;
            }

            let _winListenter = null;

            let _loadListener = function () {
                let formData = {};
                setTimeout(() => {  // delay a little so we aren't blocked
                    let formDataCand = window.localStorage.getItem('scHtmlZipData' + _showcaseId);
                    if (formDataCand) formData = JSON.parse(formDataCand);
                });

                let _store = function (data) {
                    console.log('BrowserViewerEmbedHandler store', data, formData[data.key]);
                    if (sharedKey) {
                        $.ajax({method: 'POST', url: '/api/no_auth_v1/shared-user-data', data: {
                            data_name: data.key, data_type: 'generic', workspace_id: workspaceId,
                            showcase_id: _showcaseId, content: formData[data.key], date_entered: new Date().toISOString(),
                            shared_key: sharedKey
                        }}).done(function () { // done
                        }).fail(function (jqXhr) {
                            ScNotification.growlXhrError(jqXhr, 'sending data to API');
                        });
                    } else {
                        $.ajax({method: 'POST', url: '/api/v1/data', data: {
                            data_name: data.key, data_type: 'generic', workspace_id: workspaceId,
                            showcase_id: _showcaseId, content: formData[data.key], date_entered: new Date().toISOString(),
                            user_email: userEmail
                        }}).done(function () { // done
                        }).fail(function (jqXhr) {
                            ScNotification.growlXhrError(jqXhr, 'sending data to API');
                        });
                    }
                };

                let _put = function(data) {
                    console.log('BrowserViewerEmbedHandler put', data);
                    formData[data.key] = data.value;
                    window.localStorage.setItem('scHtmlZipData' + _showcaseId, JSON.stringify(formData));
                };

                _winListenter = function (event) {
                    console.log('BrowserViewerEmbedHandler got a message', event);
                    if (event.origin !== iframeOrigin) return;

                    if (event && event.data && event.data.SHOWCASE_DATA) {
                        let data = event.data.SHOWCASE_DATA;
                        //console.log('browser-view-embed-helper.js got request:', data);
                        if (data.type === 'GET') {
                            //console.log('get', data.key);
                            event.source.postMessage({SHOWCASE_DATA_CALLBACK: {
                                key: data.key, value: formData[data.key]}}, iframeOrigin);

                        } else if (data.type === 'GETEMAIL') {
                            event.source.postMessage({
                                SHOWCASE_DATA_EMAIL_CALLBACK: {value: userEmail}}, iframeOrigin);

                        } else if (data.type === 'PUTREMOTE') {
                            _put(data);
                            _store(data);

                        } else if (data.type === 'PUT') {
                            _put(data);

                        } else if (data.type === 'STORE') {
                            _store(data);

                        } else if (data.type === 'CONTROLSHIDE') {
                            ScCommonUtil.triggerEvent('scapp.viewControlsHide');
                        } else if (data.type === 'CONTROLSSHOW') {
                            ScCommonUtil.triggerEvent('scapp.viewControlsShow');
                        } else if (data.type === 'CONTROLSBACK') {
                            ScCommonUtil.triggerEvent('scapp.viewRequestBack');
                        } else if (data.type === 'CONTROLSHOME') {  // poorly named, should be CONTROLSEXIT
                            ScCommonUtil.triggerEvent('scapp.viewRequestHome');
                        } else if (data.type === 'CONTROLSSHARE') {
                            ScCommonUtil.triggerEvent('scapp.viewRequestShare');
                        }
                    }
                };
                window.addEventListener('message', _winListenter, false);
            };
            this.isOpenedInternally = function() {
                return !!indexRelPath;
            };
            this.displayResource = function($resource) {
                if (!indexRelPath) {
                    ScNotification.growlErrMsg('Unable to find index.html in html.zip file.');
                    return;
                }
                //console.log('displayResource', resource);
                $_htmlEmbedIframe = $('<iframe>', {
                    src: 'about:blank',
                    allowfullscreen: 'true',
                    sandbox: 'allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups' +
                        ' allow-presentation allow-scripts allow-same-origin',
                    css: {position:'absolute', border: 'none',
                        backgroundColor: 'black',  // set to black while blank loads
                        left:0, top:0, width:'100%', height:'100%', zIndex: zIndex}});
                if (iframeOrigin) _loadListener();
                $($resource).append($_htmlEmbedIframe);
            };
            this.doneAnimation = function() {
                if (!indexRelPath) return;
                //console.log('doneAnimation', resource);
                $_htmlEmbedIframe.css({backgroundColor: 'white'});   // now set to white
                $_htmlEmbedIframe[0].src = indexRelPath;
            };
            this.closeResource = function() {
                //console.log('closeResource remove');
                if (_winListenter) window.removeEventListener('message', _winListenter, false);
            };
        },

        WebUrlHandler: function(resource, data, zIndex, targetUrl) {
            let $_htmlEmbedIframe = null;

            let rmdInIframe = _.findWhere(data.resourcemetadata, {resource_id: resource.id, name: 'browser_opens_inline'});
            let displayInIframe = rmdInIframe && rmdInIframe.value === 'true';

            this.isOpenedInternally = function() {
                return !!displayInIframe;
            };
            this.displayResource = function($resource) {
                if (displayInIframe) {
                    $_htmlEmbedIframe = $('<iframe>', {src: 'about:blank',
                        referrerpolicy: 'origin',
                        css: {position:'absolute', border: 'none',
                            backgroundColor: 'black',  // set to black while blank loads
                            left:0, top:0, width:'100%', height:'100%', zIndex: zIndex}});
                    $($resource).append($_htmlEmbedIframe);

                }   else {
                    window.open(targetUrl);
                }
            };
            this.doneAnimation = function() {
                if (!displayInIframe || !$_htmlEmbedIframe) return;
                //console.log('doneAnimation', resource);
                $_htmlEmbedIframe.css({backgroundColor: 'white'});   // now set to white
                $_htmlEmbedIframe[0].src = targetUrl;
            }

        },

        OpenExternallyHandler: function(resource, data, zIndex, targetUrl) {
            this.isOpenedInternally = function() {
                return false;
            };
            this.displayResource = function() {
                // skip web urls, handled when drawing hotspots, iframes are a problem for this because of https/http mixed
                // content errors and sites that set 'X-Frame-Options' to 'sameorigin'.
                window.open(targetUrl);
            };
        },

        MailToHandler: function (resource, data, zIndex, targetUrl) {
            this.isOpenedInternally = function() {
                return false;
            };
            this.displayResource = function() {
                location.href = targetUrl;
            };
        },

        PdfHandler: function(resource) {
            this.isOpenedInternally = function() {
                return true;
            };
            const isIPad = (navigator.userAgent.match(/(iPad)/) /* iOS pre 13 */ ||
                (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) /* iPad OS 13 */);
            if (/iPhone|iPod/.test(navigator.userAgent) || isIPad) {
                // mobile safari can only open the first page of a pdf (bad!), allow the user to open in new tab
                this.displayResource = function ($resource) {
                    $($resource).append(BrowserViewerEmbedHandler.getResourceAlt$(resource, 'open (in new tab)'));
                };
                this.doneAnimation = function () {};
            }  else {
                let $obj;
                this.displayResource = function() {
                    $obj = $('<object>', { type: 'application/pdf', css: {width: '100%', height: '100%'}});
                    let embedOpts = '#view=fitB&navpanes=0&toolbar=0';
                    $obj[0].data = BrowserViewerEmbedHandler.getResourceUrl(resource) + embedOpts;
                    $obj.append(BrowserViewerEmbedHandler.getResourceAlt$(resource));
                };
                this.doneAnimation = function($resource) {
                    $($resource).append($obj);  // add after the animation as pdf plugins have trouble with animations
                };
            }

        },

        DefaultHandler: function(resource) {
            this.isOpenedInternally = function() {
                return true;
            };
            this.displayResource = function($resource) {
                $($resource).append(BrowserViewerEmbedHandler.getResourceAlt$(resource));
            };
            this.doneAnimation = function() {};
        }
    },

    getResourceAlt$: function(resource, actionName) {
        let altHref = resource.url;
        if ( resource.attach_url ) altHref = resource.attach_url;
        let $alt = $('<p>', {class: 'resource-alt'});
        let $altDlLink = $('<a>', {href: altHref, rel: 'tooltip', download: resource.name, target: '_blank',
            css: {fontSize: '2.5vw'}
        });
        if (!actionName) actionName = 'download';
        $altDlLink.text('Click to ' + actionName).append($('<br>')).append($('<strong>').text(resource.name));
        $alt.append($altDlLink);
        $alt.css({paddingTop: '20vw'});
        return $alt;
    },

    openResExternally: function(r) {
        let altHref = r.url;
        if ( r.attach_url ) altHref = r.attach_url;
        window.open(altHref);
    },

    canSystemOpenWithForUrl: function() {
        return !!navigator.share;
    },

    systemOpenWithForUrl: function(url, title) {
        if (navigator.share) {
            navigator.share({url: url, title: title}).then(() => {
                // success
            }, () => {
                // ignore errors as browser shows them when dialog is cancelled
            });
        }
    },

    destroy: function() {},

    fullscreenPossible: async function() {
        //console.log('fullscreenPossible');
        return new Promise((resolve) => {
            resolve(Boolean(document.body.requestFullscreen || document.body.mozRequestFullScreen
              || document.body.webkitRequestFullscreen || document.body.msRequestFullscreen));
        });
    },

    fullscreenExit: async function() {
        let isInFullScreen = document.fullscreenElement || document.webkitFullscreenElement ||
            document.mozFullScreenElement || document.msFullscreenElement;
        if (isInFullScreen) {
            if (document.exitFullscreen) {
                await document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { /* Firefox */
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE/Edge */
                document.msExitFullscreen();
            }
        }
    },

    fullscreenToggle: async function() {
        let rootDivEles = document.getElementsByClassName('scRootDiv');
        //console.log('fullscreenToggle rootDivEles', rootDivEles);
        if (rootDivEles && rootDivEles.length > 0) {
            let isInFullScreen = document.fullscreenElement || document.webkitFullscreenElement ||
                document.mozFullScreenElement || document.msFullscreenElement;
            if (isInFullScreen) {
                await this.fullscreenExit();
            }   else {
                let elem = rootDivEles[0];
                if (elem.requestFullscreen) {
                    await elem.requestFullscreen();
                } else if (elem.mozRequestFullScreen) { /* Firefox */
                    elem.mozRequestFullScreen();
                } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                    elem.webkitRequestFullscreen();
                } else if (elem.msRequestFullscreen) { /* IE/Edge */
                    elem.msRequestFullscreen();
                }
            }
        }
    },

    lastShowcaseInFullscreen: function() {
        //console.log('lastShowcaseInFullscreen', _lastShowcaseShownInFullscreen);
        // note, we only persist this for the current page load
        return _lastShowcaseShownInFullscreen;
    },

    updateLastShowcaseShownInFullscreen: function() {
        //console.log('updateLastShowcaseShownInFullscreen');
        _lastShowcaseShownInFullscreen = !_lastShowcaseShownInFullscreen;
    },

    getResourceUrl: function(r) {
        //console.log('getResourceUrl', r);
        return  r.url;
    },

    getResourceThumbUrl: function(r) {
        //console.log('getResourceThumbUrl', r);
        return  r.thumb_url;
    },

    getResourceUrlVideo: function(r) {
        // nothing special in the browser
        return new Promise((resolve) => {
            resolve(BrowserViewerEmbedHandler.getResourceUrl(r));
        });
    },

    saveSharingRequest: function(opts) {
        return new Promise((resolve, reject) => {
            $.ajax({method: 'POST', url: '/api/v1/sharing-send-v2',
                data: {
                    to_list: opts.to_list, note: opts.note, resource_ids: opts.resource_ids, pid: opts.pid,
                    workspace_id: opts.workspace_id, shared_uuid: ScCommonUtil.guid(), subject: opts.subject,
                    open_at_options: JSON.stringify(opts.open_at_options)
                }
            }).done(function() {
                resolve();
            }).fail(function(jqXhr) {
                ScNotification.growlXhrError(jqXhr, 'sending share request')
                reject();
            });
        });
    },

    saveFormSubmission: function(opts) {
        if (opts.shared_key) {
            return new Promise((resolve, reject) => {
                $.ajax({method: 'POST', url: '/api/no_auth_v1/shared-user-data', data: {
                    data_name: opts.data_name, data_type: 'form', workspace_id: opts.workspace_id,
                    showcase_id: opts.showcase_id, content: opts.content, date_entered: opts.date_entered,
                    shared_key: opts.shared_key
                }}).done(function () {
                    ScNotification.growlSuccessMsg('Form submitted.  Thank you!');
                    resolve();
                }).fail(function (xhr) {
                    ScNotification.growlErrMsg(xhr.status + ' error sending form data');
                    reject();
                });
            });
        } else {
            return new Promise((resolve, reject) => {
                $.ajax({method: 'POST', url: '/api/v1/data', data: {
                    data_name: opts.data_name, data_type: 'form', workspace_id: opts.workspace_id,
                    showcase_id: opts.showcase_id, content: opts.content, date_entered: opts.date_entered,
                    user_email: opts.user_email
                }}).done(function () {
                    ScNotification.growlSuccessMsg('Form submitted.  Thank you!');
                    resolve();
                }).fail(function (xhr) {
                    ScNotification.growlErrMsg(xhr.status + ' error sending form data');
                    reject();
                });
            });
        }
    },

    savePageLike: function(opts) {
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'POST', url: '/api/v1/likes-v2',
                data: {
                    showcase_id: opts.showcaseId, page_id: opts.pageId, resource_id: opts.resourceId,
                    showcase_version: opts.showcaseVersion, like: opts.newLikeId !== null,
                    workspace_id: opts.workspaceId
                }
            }).done(function (data) {
                if (data.liked_id && data.like_bool) {
                    opts.id = data.liked_id;
                    opts.like_bool = data.like_bool;
                    store.commit('userUpdateLikes', opts);
                }
                resolve();
            }).fail(function (jqXhr) {
                ScNotification.growlXhrError(jqXhr, 'saving favorite state')
                reject();
            });
        });
    },

    loadLikes: function(workspaceId) {
        return new Promise((resolve) => {
            resolve(store.getters.userLikesForWorkspace(workspaceId));
        });
    },

    loadViewerTagsOfflineJson () {
        return new Promise((resolve) => {
            resolve(null);  // no offline support
        });
    },

    saveViewerTagsOfflineJson () {
        return new Promise((resolve) => {
            resolve();  // no offline support
        });
    },

    recordScAnalyticsEvent: BrowserViewerAnalyticsLogger.record,

    recordUiAnalyticsScreenView: ScVueTrackEvents.trackViewerScreenView,

    recordUiAnalyticsEvent: ScVueTrackEvents.trackViewerEvent,

    preloadNow: BrowserViewerPreload.preloadNow,

    contactSuggestionsSearch: function(opts) {
        _contactSuggestionListWorkspaceId = opts.workspaceId;
        return new Promise((resolve) => {
            $.ajax({
                type: "POST",
                url: "/main/sharing/ajax_get_all_lead_emails",
                data: {
                    workspace_id: _contactSuggestionListWorkspaceId
                }
            }).done(function(data){
                if (data.leads)
                    resolve(data.leads);
                else
                    resolve([]);

            }).fail(function(jqXhr) {
                ScNotification.growlXhrError(jqXhr, "fetching contact suggestions");
                resolve([])
            });
        });
    },

    shouldDisplayNextPrevArrowsWhenSettingUndefined() {
        return true;
    },

    ajaxBeforeSend: function() {
        // handled by cookie
    },

    ajaxWrapUrl: function(urlPath) {
        return urlPath;  // relative nothing to do
    },

};

export default BrowserViewerEmbedHandler;


